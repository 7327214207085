import React, { Component } from "react";


class Screenshot extends Component {
    render() {
        return (
            <div className="screenshot-area pt--120" id="screenshots">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                <h2>SOME OF <span className="theme-color">OUR WORKS</span></h2>
                                {/*   <img className="image-1" src="/assets/images/app/title-icon.png" alt="App Landing"/>
                                <img className="image-2" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                                <img className="image-3" src="/assets/images/app/title-icon-3.png" alt="App Landing"/> */}
                                <p>We developed and delivered quality custom systems and online services to our clients in different sectors</p></div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="screenshot-carousel" id="screenshot-carousel" data-carousel-3d>
                                <img className="image-1" src="/assets/images/services/shegerauto-mock.png" alt="ShegerAuto" />
                                <img className="image-1" src="/assets/images/systems/bnbsnapshot-mock.png" alt="BNBSnapshot" />
                                <img className="image-1" src="/assets/images/app/hulu-tender-mock-2.png" alt="Hulu Tender" />
                                <img className="image-1" src="/assets/images/app/Berrymuch.jpeg" alt="Berrymuch" />
                                <img className="image-1" src="/assets/images/websites/glory_car_rent.png" alt="Glory Car Rental" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Screenshot;










