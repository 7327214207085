// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
//import Demo from './page-demo/Demo';
import HomeOlive from './HomeOlive';
import HomeHorizontal from './HomeHorizontal';
import BlogGrid from './blog/BlogGrid';

import BlogTwoColumn from './blog/BlogTwoColumn';
import BlogDetails from './blog/BlogDetails';


import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import HuluTenderPrivacy from './privacy/hulu_tender';
import DeboPrivacy from './privacy/debo';
import EbuyPrivacy from './privacy/ebuy';
import VivaSoundsPrivacy from './privacy/viva_sounds';
import HuluTenderTermsOfService from './terms/hulutender';
import DeboTermsOfService from './terms/debo';
import EbuyTermsOfService from './terms/ebuy';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeHorizontal} />
                    <Route exact path={`${process.env.PUBLIC_URL}/home-one`} component={HomeOlive} />
                    <Route exact path={`${process.env.PUBLIC_URL}/home-two`} component={HomeHorizontal} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-grid`} component={BlogGrid} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-two-column`} component={BlogTwoColumn} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy/hulutender`} component={HuluTenderPrivacy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy/debo`} component={DeboPrivacy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy/ebuy`} component={EbuyPrivacy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy/viva_sounds`} component={VivaSoundsPrivacy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/terms/hulutender`} component={HuluTenderTermsOfService} />
                    <Route exact path={`${process.env.PUBLIC_URL}/terms/debo`} component={DeboTermsOfService} />
                    <Route exact path={`${process.env.PUBLIC_URL}/terms/ebuy`} component={EbuyTermsOfService} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();


