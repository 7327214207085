import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class DeboTermsOfService extends Component {
    render() {
        return (
            <div>
                <div className="blog-details pl--30 pt--30 pb--60 pr--30  pt_md--80 pt_sm--80 bg-color">

                    <h4><u><strong>Terms &amp; Conditions</strong></u></h4> <p>
                        By downloading or using the "Debo" mobile application (“<strong>The App</strong>”) , these terms will
                        automatically apply to you – you should make sure therefore
                        that you read them carefully before using the app. You’re not
                        allowed to copy or modify the app, any part of the app, or
                        our trademarks in any way. You’re not allowed to attempt to
                        extract the source code of the app, and you also shouldn’t try
                        to translate the app into other languages or make derivative
                        versions. The app itself, and all the trademarks, copyright,
                        database rights, and other intellectual property rights related
                        to it, still belong to Kavana Technologies.
                    </p> <p>
                        Kavana Technologies is committed to ensuring that the app is
                        as useful and efficient as possible. For that reason, we
                        reserve the right to make changes to the app or to charge for
                        its services, at any time and for any reason. We will never
                        charge you for the app or its services without making it very
                        clear to you exactly what you’re paying for.
                    </p> <p>
                        The Debo app stores and processes personal data that
                        you have provided to us, to provide our
                        Service. It’s your responsibility to keep your phone and
                        access to the app secure. We therefore recommend that you do
                        not jailbreak or root your phone, which is the process of
                        removing software restrictions and limitations imposed by the
                        official operating system of your device. It could make your
                        phone vulnerable to malware/viruses/malicious programs,
                        compromise your phone’s security features and it could mean
                        that the Debo app won’t work properly or at all.
                    </p> <div><p>
                        The app does use third-party services that declare their
                        Terms and Conditions.
                    </p> <p>
                            Link to Terms and Conditions of third-party service
                            providers used by the app
                        </p> <ul>
                            <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                            <li><a href="https://www.apple.com/legal/internet-services/terms/site.html" target="_blank" rel="noopener noreferrer">Appstore Services</a></li>
                        </ul></div> <p>
                        You should be aware that there are certain things that
                        Kavana Technologies will not take responsibility for. Certain
                        functions of the app will require the app to have an active
                        internet connection. The connection can be Wi-Fi or provided
                        by your mobile network provider, but Kavana Technologies
                        cannot take responsibility for the app not working at full
                        functionality if you don’t have access to Wi-Fi, and you don’t
                        have any of your data allowance left.
                    </p> <p></p> <p>
                        If you’re using the app outside of an area with Wi-Fi, you
                        should remember that the terms of the agreement with your
                        mobile network provider will still apply. As a result, you may
                        be charged by your mobile provider for the cost of data for
                        the duration of the connection while accessing the app, or
                        other third-party charges. In using the app, you’re accepting
                        responsibility for any such charges, including roaming data
                        charges if you use the app outside of your home territory
                        (i.e. region or country) without turning off data roaming. If
                        you are not the bill payer for the device on which you’re
                        using the app, please be aware that we assume that you have
                        received permission from the bill payer for using the app.
                    </p> <p>
                        Along the same lines, Kavana Technologies cannot always take
                        responsibility for the way you use the app i.e. You need to
                        make sure that your device stays charged – if it runs out of
                        battery and you can’t turn it on to avail the Service,
                        Kavana Technologies cannot accept responsibility.
                    </p> <p>
                        With respect to Kavana Technologies’s responsibility for your
                        use of the app, when you’re using the app, it’s important to
                        bear in mind that although we endeavor to ensure that it is
                        updated and correct at all times, we do rely on third parties
                        to provide information to us so that we can make it available
                        to you. Kavana Technologies accepts no liability for any
                        loss, direct or indirect, you experience as a result of
                        relying wholly on this functionality of the app.
                    </p> <p>
                        At some point, we may wish to update the app. The app is
                        currently available on Android – the requirements for the
                        system(and for any additional systems we
                        decide to extend the availability of the app to) may change,
                        and you’ll need to download the updates if you want to keep
                        using the app. Kavana Technologies does not promise that it
                        will always update the app so that it is relevant to you
                        and/or works with the Android version that you have
                        installed on your device. However, you promise to always
                        accept updates to the application when offered to you, We may
                        also wish to stop providing the app, and may terminate use of
                        it at any time without giving notice of termination to you.
                        Unless we tell you otherwise, upon any termination, (a) the
                        rights and licenses granted to you in these terms will end;
                        (b) you must stop using the app, and (if needed) delete it
                        from your device.
                    </p>


                    <p><strong>Disclaimer</strong></p>
                    <p>
                        The App and its contents are delivered on an "AS-IS" and "AS-AVAILABLE" basis. Kavana Technologies does not promise that the app or any content, service or feature of the app will be
                        error-free or uninterrupted or that any defects will be corrected or your use of the app will provide
                        specific results. All information provided on the app is subject to change at anytime without notice.
                        Kavana Technologies disclaims all warranties, express or implied, including any warranties of accuracy,non-infringement merchantability and fitness for a
                        particular purpose.
                        Kavana Technologies disclamins any and all liability for the acts, omissions and conduct of third parties in connection with or related to your use of the app and related services offered
                        by Kavana Technologies. You assume total responsibility for your use of the app and any linked sites.
                        Your sole remedy against Kavana Technologies for dissatisfaction with the app or any of its contents is to stop using the app or its content. This limitation is
                        a bargain between Kavana Technologies and the end user.
                    </p>

                    <p><strong>Indemnity</strong></p>
                    <p>
                        You agree to indemnify and hold Kavana Technologies, its officers, directors, shareholders, predecessors, successors in interest, employees, agents,
                        subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against
                        Kavana Technologies by any third party due to or arising out of or in connection with your use of the Site.
                    </p>

                    <p><strong>Violation of this terms of use</strong></p>
                    <p>
                        Kavana Technologies may disclose any information we have about you (including your identity) if we determine that such disclosure is necessary in connection with any
                        investigation or complaint regarding your use of the App, or to identify, contact or bring legal action against someone who may be causing injury to or interference
                        with (either intentionally or unintentionally) Kavana Technologie’s rights or property, or the rights or property of visitors to or users of the App, including
                        Kavana Technologies’s customers. Kavana Technologies reserves the right at all times to disclose any information that Kavana Technologies deems necessary to comply
                        with any applicable law, regulation, legal process or governmental request. Kavana Technologies also may disclose your information when Kavana Technologies determines
                        that applicable law requires or permits such disclosure, including exchanging information with other companies and organizations for fraud protection purposes.
                    </p><p>
                        You acknowledge and agree that Kavana Technologies may preserve any transmittal or communication by you with Kavana Technologies through the App or any service offered
                        on or through the App, and may also disclose such data if required to do so by law or Kavana Technologies determines that such preservation or disclosure is reasonably
                        necessary to
                        (1) comply with legal process,
                        (2) enforce these Terms of Use,
                        (3) respond to claims that any such data violates the rights of others, or
                        (4) protect the rights, property or personal safety of Kavana Technologies, its employees, users of or users to the App, and the public.

                        You agree that Kavana Technologies may, in its sole discretion and without prior notice, terminate your access to the App and/or block your future access to the App if
                        we determine that you have violated these Terms of Use or other agreements or guidelines which may be associated with your use of the App.
                    </p><p>
                        You also agree that any violation by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Kavana Technologies,
                        for which monetary damages would be inadequate, and you consent to Kavana Technologies obtaining any injunctive or equitable relief that Kavana Technologies deems necessary or
                        appropriate in such circumstances. These remedies are in addition to any other remedies Kavana Technologies may have at law or in equity.
                    </p><p>
                        You agree that Kavana Technologies may, in its sole discretion and without prior notice, terminate your access to the App, for cause, which includes (but is not limited to)
                        (1) requests by law enforcement or other government agencies,
                        (2) a request by you (self-initiated account deletions),
                        (3) discontinuance or material modification of the App or any service offered on or through the App, or
                        (4) unexpected technical issues or problems.

                        If Kavana Technologies does take any legal action against you as a result of your violation of these Terms of Use, Kavana Technologies will be entitled to recover from you, and you agree to pay,
                        all reasonable attorneys’ fees and costs of such action, in addition to any other relief granted to Kavana Technologies. You agree that Kavana Technologies will not be liable to you or to any
                        third party for termination of your access to the Site as a result of any violation of these Terms of Use.

                    </p>

                    <p><strong>Dispute Resolution and Governing Law</strong></p>
                    <p>You agree that all matters relating to your access to or use of the App, including all disputes,
                        will be governed by the laws of Ethiopia. You agree to the personal jurisdiction by and venue in the state
                        and federal courts in Ethiopia, and waive any objection to such jurisdiction or venue.
                        Any claim under these Terms of Use must be brought within one (1) year after the cause of action arises, or
                        such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services
                        are not subject to this limitation. No recovery may be sought or received for damages other than out-of-pocket expenses,
                        except that the prevailing party will be entitled to costs and attorneys’ fees.
                    </p>
                    <p>
                        In the event of any controversy or
                        dispute between Kavana Technologies and you arising out of or in connection with your use of the App, the parties shall attempt,
                        promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a
                        reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation.
                        If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available
                        to them under applicable law.</p>

                    <p><strong>About This Terms</strong></p>

                    <p>By law, you have certain rights that can’t be limited by a contract like these terms of service.
                        These terms are in no way intended to restrict those rights.
                        These terms describe the relationship between you and Kavana Technologies regarding usage of the App and/or services offered through the app. They don’t create any legal rights for other people or organizations, even if others benefit from that relationship under these terms.

                        If these terms conflict with the service-specific additional terms that you may be provided in person, the additional terms will govern for that service.

                        If it turns out that a particular term is not valid or enforceable, this will not affect any other terms.
                    </p>
                    <p>
                        We may update our Terms and Conditions
                        from time to time. Thus, you are advised to review this page
                        periodically for any changes. We will
                        notify you of any changes by posting the new Terms and
                        Conditions on this page.
                    </p>
                    <p>These terms and conditions are updated as of 2022-11-18
                    </p> <p><strong>Contact Us</strong></p> <p>
                        If you have any questions or suggestions about our
                        Terms and Conditions, do not hesitate to contact us
                        at kavana.et@gmail.com.
                    </p>





                </div>

                {/* Footer */}
                {/* <Footer horizontal="horizontal" /> */}

            </div>
        )
    }
}

export default DeboTermsOfService;
