import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            icon: 'zmdi zmdi-collection-text',
            title: 'Software Development',
            desc: 'We provide a high quality custom software by following trending technological advancements and employing the proper tools'
        },

        {
            icon: 'zmdi zmdi-sun',
            title: 'ICT Consultancy',
            desc: 'We provide a technology consultancy service to help clients transform the way they use technology'
        },

        {
            icon: 'zmdi zmdi-brush',
            title: 'Network Infrastructure',
            desc: 'In collaboration with thrid party providers we provide network infrastructure installation and configuration services'
        },

        {
            icon: 'zmdi zmdi-desktop-mac',
            title: 'Business Automation',
            desc: 'We help businesses improve their efficiency and properly utilize their resources with reduced cost by leveraging technology'
        },

        {
            icon: 'zmdi zmdi-language-html5',
            title: 'ERP System Provison',
            desc: 'We provide our ERP system which is designed to facilitate and streamline the core operations of a business'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       <i className={`${val.icon}`}></i>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2><span className="theme-color">What</span> WE DO</h2>
                                {/* <img className="image-1" src="/assets/images/app/title-icon.png" alt="App Landing"/>
                                <img className="image-2" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                                <img className="image-3" src="/assets/images/app/title-icon-3.png" alt="App Landing"/> */}
                                <p>Kavana provides a wide range of ICT services including but not limited to state of the art quality customer software development</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
