import React, { Component } from "react";


class About extends Component {
    render() {
        return (
            <div className={`app-about ${this.props.horizontalabout}`} id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2><span className="theme-color">Who</span> We are</h2>
                                {/*  <img className="image-1" src="/assets/images/app/title-icon.png" alt="App Landing"/>
                                <img className="image-2" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                                <img className="image-3" src="/assets/images/app/title-icon-3.png" alt="App Landing"/> */}
                                <p>We are a team of professionals who appreciate technology and the ease it brings to day to day life, and we believe in utilizing it to solve critical problems in society and businesses</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src="/assets/images/bg/about_kavana.png" alt="Kavana Tech" />
                                <img className="image-2" src="/assets/images/bg/about_kavana.png" alt="Kavana Tech" />
                                <img className="image-3" src="/assets/images/bg/about_kavana.png" alt="Kavana Tech" />
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title"><span className="theme-color">Vision</span> Focused</h2>
                                <p>We have a clear vision and direction of becoming the best technology service provider in Ethiopia and contributing to global technological advancements. We beleve in keeping up-to-date with the bleeding-edge global technological advancements and adapting quickly to provide our customers with the best service they could ever need.</p>
                                <p>We are striving to create a company culture that focuses on creating a positive, welcoming and collaborative environment where each and every staff will be appreciated and treated with the utmost respect they deserve as a human being</p>
                                <div className="about-buttons">
                                    {/*   <button type="button" className="button-default button-olive button-border">Download</button> */}
                                    <a className="button-default button-border" href="/" role="button">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











