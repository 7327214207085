import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class DeboPrivacy extends Component {
    render() {
        return (
            <div>
                <div className="blog-details pl--30 pb--30 pt--30 pr--30 pt_md--80 pt_sm--80 bg-color">


                    <h4><u><strong>Privacy Policy</strong></u></h4>
                    <p>
                        <p>The "Debo" mobile application (“<strong>The App</strong>”) powered by Kavana Technologies – helps you get involved in a regulated saving mechanism by providing you the necessary platform and facilitating services to achieve your financial goals.</p>
                        <p>The App allows you to:</p>
                        <ul>
                            <li>Search and Join Equbs</li>
                            <li>Manage your money</li>
                            <li>View your transactions and receipts</li>
                            <li>Confirm your payments</li>
                            <li>Involve in a live lotto draw</li>
                            <li>Buy and/or Sale your winnings</li>
                            <li>Receive notifications for your activities</li>
                            <li>Signup and Signin using your google account</li>
                            <li>Track ratings of yourself and the people you are involved in an Equb with</li>
                        </ul>

                        Kavana Technologies built the <b>Debo</b> app as
                        a Free app, but we may charge a service charge based on the type of service the user may decide to join.
                    </p>

                    <p>This Privacy Policy, in combination with other relevant privacy notices tshat we provide to you (e.g., pursuant to financial privacy laws), inform you of the policies and practices regarding the collection, use and disclosure of any personal information that we and our service providers collect from or about users in connection with the App’s, website and mobile application (the “<strong>Services</strong>”).</p>

                    <p>
                        This page is used to inform visitors regarding our
                        policies with the collection, use, and disclosure of Personal
                        Information if anyone decided to use our Service.
                    </p> <p>
                        If you choose to use our Service, then you agree to
                        the collection and use of information in relation to this
                        policy. The Personal Information that we collect is
                        used for providing and improving the Service. We will not use or share your information with
                        anyone except as described in this Privacy Policy.
                    </p> <p>
                        The terms used in this Privacy Policy have the same meanings
                        as in our Terms and Conditions, which are accessible at
                        Debo unless otherwise defined in this Privacy Policy.
                    </p> <p><strong>Information Collection and Use</strong></p> <p>
                        For a better experience, while using our Service, we
                        may require you to provide us with certain personally
                        identifiable information, including but not limited to Name, Email, UserIDs, Phone Number,. The information that
                        we request will be retained by us and used as described in this privacy policy.
                    </p> <div><p>
                        The app does use third-party services that may collect
                        information used to identify you.
                    </p> <p>
                            Link to the privacy policy of third-party service providers used
                            by the app
                        </p> <ul><li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                            <li><a href="https://www.apple.com/legal/privacy/" target="_blank" rel="noopener noreferrer">Appstore Services</a></li>
                        </ul></div>  <p>
                        This Service does not use these “cookies” explicitly. However,
                        the app may use third-party code and libraries that use
                        “cookies” to collect information and improve their services.
                        You have the option to either accept or refuse these cookies
                        and know when a cookie is being sent to your device. If you
                        choose to refuse our cookies, you may not be able to use some
                        portions of this Service.</p>
                    <p>

                        <p><strong>Types of information we collect in the app</strong></p>
                        <p>Through your use of the Services, we may collect personal information from you in the following ways:</p>
                        <p>(a) Personal Information You Provide to Us.</p>
                        <ul>
                            <li>We may collect personal information from you, such as your first and last name and e-mail address when you create an account.</li>
                            {/* <li>We will collect the financial and transaction information necessary to provide you with the Services, including account numbers and transaction and payment history.</li> */}
                            <li>If you provide feedback or contact us via email, we will collect your name and email address, as well as any other content included in the email, in order to send you a reply.</li>
                            <li>We also collect other types of personal information that you provide voluntarily, such as any information requested by us if you contact us via email regarding support for the Services.</li>
                        </ul>
                        <p>(b) Personal Information Collected from Third Parties. We may collect certain information from identity verification services and consumer reporting agencies, including google/apple services in order to provide some of our Services.</p>
                        <p>(c) Personal Information Collected Via Technology. We and our service providers may automatically log information about your interaction over time with our Services, our communications and other online services, such as:</p>
                        <ul>
                            {/* <li><a name="_Toc529964440" class="mce-spacer"></a><a name="_Toc531681058" class="mce-spacer"></a><a name="_Toc1737940" class="mce-spacer"></a>Device data, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers, language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G), and general location information such as city, state or geographic area.</li>
                            <li>Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.</li>
                             */}



                            <li>
                                <p><strong>Log Data: </strong>
                                    We want to inform you that whenever you
                                    use our Service, in a case of an error in the app
                                    we collect data and information (through third-party
                                    products) on your phone called Log Data. This Log Data may
                                    include information such as your device Internet Protocol
                                    (“IP”) address, device name, operating system version, the
                                    configuration of the app when utilizing our Service,
                                    the time and date of your use of the Service, and other
                                    statistics.
                                </p>
                                <li><p><strong>Cookies: </strong>
                                    which are text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, and helping us understand user activity and patterns.</p> </li>
                            </li>
                            <li><strong>Local storage technologies:</strong> like HTML5 and Flash, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.</li>
                            {/* <li>Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.</li>
                            <li>Location Information. If you have enabled location services on your phone and agree to the collection of your location when prompted by the Services, we will collect your location information when you use the Services; for example, to provide our fraud detection services. If you do not want us to collect this information, you may decline the collection of your location when prompted or adjust the location services settings on your device.</li> */}
                        </ul>
                        <p><strong>HOW WE USE YOUR INFORMATION COLLECTED IN THE APP</strong></p>
                        <p>(a) General Use. In general, we use your personal information collected through your use of the Services to respond to your requests as submitted through the Services, to provide you the Services you request, and to help serve you better. We use your personal information, in connection with the App, in the following ways:</p>
                        <ul>
                            <li>facilitate the creation of, and secure and maintain your account;</li>
                            <li>identify you as a legitimate user in our system;</li>
                            <li>provide improved administration of the Services;</li>
                            <li>provide the Services you request;</li>
                            <li>improve the quality of experience when you interact with the Services;</li>
                            <li>send you administrative e-mail notifications, such as security or support and maintenance advisories; and</li>
                            <li>send surveys, offers, and other promotional materials related to the Services.</li>
                        </ul>
                        <p>(b) Compliance and protection. We may use your personal information to:</p>
                        <ul>
                            <li>comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities;</li>
                            <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                            <li>audit our internal processes for compliance with legal and contractual requirements and internal policies;</li>
                            <li>enforce the terms and conditions that govern the Service; and</li>
                            <li>prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</li>
                        </ul>
                        <p>(c) Creation of Non-Identifiable Data. The App may create de-identified information records from personal information by excluding certain information (such as your name) that makes the information personally identifiable to you.
                            We may use this information in a form that does not personally identify you to analyze request patterns and usage patterns to enhance our products and services.
                            {/* We reserve the right to use and disclose non-identifiable information to third parties in our discretion. */}
                        </p>
                        <p><strong>DISCLOSURE OF YOUR PERSONAL INFORMATION</strong></p>
                        <p>We disclose your personal information collected through your use of the Services as described below.</p>
                        <p>(a) In Accordance with Our Other Privacy Notices. Other than as described in this Privacy Policy in connection with the App, this Privacy Policy does not apply to the processing of your information by us
                            {/* or third parties with whom we share information. */}
                        </p>
                        {/* <p>(b) Third Party Service Providers. We may share your personal information with third party or affiliated service providers that perform services for or on behalf of us in providing the App, for the purposes described in this Privacy Policy, including: to provide you with the Services; to conduct quality assurance testing; to facilitate the creation of accounts; to optimize the performance of the Services; to provide technical support; and/or to provide other services to the App.</p>
                        */}
                        <p>&nbsp;(b) Authorities and Others. Regardless of any choices you make regarding your personal information, The App may disclose your personal information to law enforcement, government authorities, and private parties, for the compliance and protection services described above.</p>


                        <p><strong>YOUR CHOICES REGARDING YOUR INFORMATION</strong></p>
                        <p>You have several choices regarding use of information on the Services.</p>
                        <p>&nbsp;(a) How We Respond to Do Not Track Signals. Some web browsers transmit “do not track” signals to the websites and other online services with which your web browser communicates. There is currently no standard that governs what, if anything, websites should do when they receive these signals. We currently do not take action in response to these signals. If and when a standard is established, we may revise its policy on responding to these signals.</p>
                        <p>(b) Access, Update, or Correct Your Information. &nbsp;You can access, update or correct your information by changing preferences in your account. For additional requests, please contact us.&nbsp;</p>
                        <p>(c) Opting Out of Email or SMS Communications. If you have signed-up to receive our email marketing communications, you can unsubscribe any time by clicking the "unsubscribe" link included at the bottom of the email or other electronic communication. Alternatively, you can opt out of receiving marketing communications by contacting us at the contact information under "Contact Us" below. If you provide your phone number through the Services, we may send you notifications by SMS, such as provide a fraud alert. You may opt out of SMS communications by unlinking your mobile phone number through the Services.</p>
                        <p>(d) You have the option of formally submitting a request for us to delete/erase information related to you (Information you made available willingly) from our databases.</p>

                        {/* <p>(d) Opting Out of Location Tracking. If you initially consented to the collection of geo-location information through the Services, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device. Please note, however, that if you withdraw consent to our collection of location information, you may no longer be able to use some features of the App.</p> */}
                        <p><strong>A NOTE ABOUT CHILDREN</strong></p>
                        <p>The Services are not directed towards individuals under the age of 18, and we do not, through the App, intentionally gather personal information about visitors who are under the age of 18. If a child under 18 submits personal information to us through the App and we learn that the personal information is the information of a child under 18, we will attempt to delete the information as soon as possible.</p>
                        <p><strong>PRIVACY POLICY UPDATES</strong></p>
                        <p>This Privacy Policy is subject to occasional revision. We will notify you of any material changes in its collection, use, or disclosure of your personal information by posting a notice on the Services. Any material changes to this Privacy Policy will be effective thirty (30) calendar days following notice of the changes on the Services. These changes will be effective immediately for new users of the Services. If you object to any such changes, you must notify us prior to the effective date of such changes that you wish to deactivate your account. Continued use of the Services following notice of any such changes shall indicate your acknowledgement of such changes.</p>
                        <p><strong>CONTACT US</strong></p>
                        <p>If you have any questions or complaints about this Privacy Policy or The App’s data collection or processing practices, or if you want to report any security violations to The App, please contact The App by email at: <a href="mailto:kavana.et@gmail.com">mailto:kavana.et@gmail.com</a></p>
                        {/* <div>Kavana Technologies PLC</div>
                        <div>PO Box 1000</div>
                        <div>Addis Ababa,Ethiopia</div> */}




                        <p><strong>Children’s Privacy</strong></p> <div><p>
                            These Services do not address anyone under the age of 13.
                            We do not knowingly collect personally
                            identifiable information from children under 13 years of age. In the case
                            we discover that a child under 13 has provided
                            us with personal information, we immediately
                            delete this from our servers. If you are a parent or guardian
                            and you are aware that your child has provided us with
                            personal information, please contact us so that
                            we will be able to do the necessary actions.
                        </p></div>









                    </p>

                    {/* <p><strong>Service Providers</strong></p> <p>
                                We may employ third-party companies and
                                individuals due to the following reasons:
                            </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                                We want to inform users of this Service
                                that these third parties have access to their Personal
                                Information. The reason is to perform the tasks assigned to
                                them on our behalf. However, they are obligated not to
                                disclose or use the information for any other purpose.
                            </p> */}

                    <p><strong>Changes to This Privacy Policy</strong></p> <p>
                        We may update our Privacy Policy from
                        time to time. Thus, you are advised to review this page
                        periodically for any changes. We will
                        notify you of any changes by posting the new Privacy Policy on
                        this page.
                    </p> <p>This policy is last updated on as of 2022-11-29</p> <p><strong>Contact Us</strong></p> <p>
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at kavana.et@gmail.com.
                    </p>

                </div>


                {/* Footer */}
                {/* <Footer horizontal="horizontal" /> */}

            </div>
        )
    }
}

export default DeboPrivacy;














